import * as React from "react";
import ContactForm from "../components/Forms/ContactForm";

import OrderPhaseIndicator from "../components/Order/OrderPhaseIndicator";
import CartFoot from "../components/CartFoot";

const Contact = () => {
  return (
    <>
      <OrderPhaseIndicator phase={1} />
      <ContactForm />
      <CartFoot buttonText={"Pokračovat"} />
    </>
  );
};

export default Contact;
