import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Form } from "formik";
import { Box, Paper, Typography, Stack } from "@mui/material";
import TextInput from "./TextInput";
import GroupForm from "./GroupForm";
import CustomDatePicker from "./DatePicker";

interface Values {
  fullName: string;
  tel: string;
  email: string;
  transport: string;
  address: string;
  dateFrom: Date;
  dateTo: Date;
}

const ContactForm = () => {
  const theme = useTheme();

  return (
    <Form noValidate id="contactForm">
      <Paper
        sx={{
          mx: 2,
          mb: 2,
          mt: 3,
          py: 1.75,
          [theme.breakpoints.up("sm")]: {
            maxWidth: "358px",
            mx: "auto",
          },
        }}
      >
        <Typography sx={{ ml: 2, pb: 1.5, fontWeight: "bold" }}>
          Datum rezervace
        </Typography>
        <Stack spacing={2} direction="row" sx={{ mx: 2 }}>
          {" "}
          <CustomDatePicker
            name="dateFrom"
            label="Od"
            placeholder="dd.mm.rrrr"
          />
          <CustomDatePicker name="dateTo" label="Do" placeholder="dd.mm.rrrr" />
        </Stack>
      </Paper>
      <Paper
        sx={{
          m: 2,
          py: 1.75,
          [theme.breakpoints.up("sm")]: {
            maxWidth: "358px",
            mx: "auto",
          },
        }}
      >
        <Typography sx={{ fontWeight: "bold", ml: 2, pb: 1.5 }}>
          Kontakt
        </Typography>
        <Stack spacing={2} sx={{ mx: 2 }}>
          <TextInput
            label="Jméno a příjmení"
            name="fullName"
            type="text"
            placeholder="Jméno a příjmení"
          />
          <TextInput
            label="Telefon"
            name="tel"
            type="tel"
            placeholder="Telefon"
          />
          <TextInput
            label="E-mail"
            name="email"
            type="email"
            placeholder="E-mail"
          />
        </Stack>
      </Paper>
      <Paper
        sx={{
          mt: 2,
          mx: 2,
          mb: "80px",
          py: 1.75,
          [theme.breakpoints.up("sm")]: {
            maxWidth: "358px",
            mx: "auto",
          },
        }}
      >
        <Typography sx={{ fontWeight: "bold", ml: 2, pb: 1.5 }}>
          Doprava
        </Typography>
        <Box sx={{ pl: 3 }}>
          <GroupForm
            fields={[
              {
                label: "Potřebuji dopravu",
                value: "Potřebuji dopravu",
              },
              {
                label: "Odvezu si sám",
                value: "Odvezu si sám",
              },
            ]}
            name={"transport"}
          />
        </Box>
        <Box sx={{ mx: 1, mt: 2 }}>
          <TextInput
            multiline
            rows={3}
            label="Adresa"
            name="address"
            type="text"
            placeholder="Adresa"
          />
        </Box>
      </Paper>
    </Form>
  );
};

export default ContactForm;
