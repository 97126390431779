import * as React from "react";
import { useField } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl } from "@mui/material";

export interface SingleRadioProps {
  label: string;
  value: string;
  color: string;
}

export interface GroupFormProps {
  fields: Array<SingleRadioProps>;
  name: string;
}

const GroupForm = ({ fields, name }: GroupFormProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl>
      <RadioGroup name={name} value={field.value}>
        {fields.map(
          (item: SingleRadioProps): JSX.Element => (
            <FormControlLabel
              label={item.label}
              control={<Radio {...field} value={item.value} color="success" />}
            />
          )
        )}
      </RadioGroup>
    </FormControl>
  );
};
export default GroupForm;
