import * as React from "react";
import { SvgIcon } from "@mui/material";

const CalendarIcon = (props): JSX.Element => {
  return (
    <SvgIcon
      width="18"
      height="20"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9H4v2h2V9Zm4 0H8v2h2V9Zm4 0h-2v2h2V9Zm2-7h-1V0h-2v2H5V0H3v2H2A2 2 0 0 0 .01 4L0 18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm0 16H2V7h14v11Z" />
    </SvgIcon>
  );
};
export default CalendarIcon;
