import * as React from "react";
import { useField } from "formik";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

export interface TextInputProps {
  name: string;
  type: "text" | "email" | "tel";
}

const TextInput = ({
  label,
  name,
  sx,
  placeholder,
  type = "text",
  ...props
}: TextFieldProps & TextInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name); // => "name"

  return (
    <MuiTextField
      sx={{ ...sx, width: "100%" }}
      {...field}
      {...props}
      color="active"
      type={type}
      label={label}
      placeholder={placeholder}
      name={name}
      variant="outlined"
      error={meta.error ? true : false}
      helperText={meta.error}
    />
  );
};

export default TextInput;
