import { DatePicker } from "@mui/x-date-pickers";
import { useField } from "formik";
import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarIcon from "../icons/CalendarIcon";
import TextInput from "./TextInput";

const CustomDatePicker = ({ label, name, placeholder, ...props }) => {
  const [field, meta, { setValue }] = useField(name);
  return (
    <DatePicker
      name={name}
      label={label}
      value={field.value}
      mask="__.__.____"
      inputFormat="dd.MM.yyyy"
      onChange={(newValue) => {
        setValue(newValue);
      }}
      inputProps={{
        placeholder: placeholder, // for desktop
      }}
      renderInput={(params) => {
        return (
          <TextInput
            name={name}
            {...params}
            onChange={() => null}
            placeholder={placeholder} // for phone
            endAdornment={
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            }
          />
        );
      }}
    />
  );
};

export default CustomDatePicker;
